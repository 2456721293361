import { Customer } from '@medusajs/medusa';

import { Dealer } from '../types/entities';

export enum DEALER_STAFF_ROLE {
  SALE_REPRESENTATIVE = 'sales-representative',
}

export const getManagedDealers = (customer: Customer): Dealer[] => {
  if (!customer.dealerStaffs) return [];

  return customer.dealerStaffs
    .filter((staff) => staff.role === DEALER_STAFF_ROLE.SALE_REPRESENTATIVE)
    .map((member) => member.dealer);
};

export const getDynamic365CustomerId = (customer: Customer) => {
  const customerDynamic365Id = customer.metadata?.dynamic_365_customer_id as
    | string
    | undefined;

  const [group] = customer.groups;
  const groupDynamic365Id = group?.metadata?.dynamic_365_customer_id as
    | string
    | undefined;

  return groupDynamic365Id ?? customerDynamic365Id ?? '-';
};
