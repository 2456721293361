import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';

import { getManagedDealers } from '../../helpers/customer.helper';
import { useCustomer } from '../../hooks/use-customer';
import AccountIcon from '../../icons/account';
import AccountMultiple from '../../icons/account-multiple';
import DocumentIcon from '../../icons/document';

export const BASE_ACCOUNT_PATH = '/account';
export const PROFILE_PATH = BASE_ACCOUNT_PATH;
export const ADDRESS_PATH = `${BASE_ACCOUNT_PATH}/addresses`;
export const PASSWORD_SETTING_PATH = `${BASE_ACCOUNT_PATH}/reset-password`;
export const COMPANY_PATH = `${BASE_ACCOUNT_PATH}/company`;
export const DEALER_PATH = `${BASE_ACCOUNT_PATH}/dealer`;
export const ORDERS_PATH = '/orders';
export const DEALER_ORDERS_PATH = '/dealer-orders';

const getColor = (path: string, pathName: string) =>
  path.startsWith(pathName) ? '!text-primary-6' : '!text-light-title';

const AccountLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col overflow-hidden md:flex-row">
      <div className="mb-6 h-fit w-full rounded-[6px] border border-light-divider bg-neutral-1 shadow-small md:mb-0 md:mr-6 md:w-[240px] md:min-w-[200px]">
        <MyAccountMenu />
        <MemberMenu />
        <OrderMenu />
      </div>
      <div className="flex-1 overflow-hidden">{children}</div>
    </div>
  );
};

const MyAccountMenu = () => {
  const location = useLocation();
  const path = location.pathname;

  const isActiveMenu =
    path === PROFILE_PATH ||
    [ADDRESS_PATH, PASSWORD_SETTING_PATH].some((item) => path.startsWith(item));

  return (
    <>
      <Link
        to={PROFILE_PATH}
        className={`flex items-center border-b border-light-divider px-4 py-2 font-semibold hover:!text-primary-6 ${isActiveMenu ? 'text-primary-6' : 'text-light-title'}`}
      >
        <AccountIcon />
        <div className="ml-2">บัญชีของฉัน</div>
      </Link>
      <div className="flex flex-col bg-light-lighter p-4 pl-12">
        <Link
          className={`mb-4 hover:!text-primary-6 ${
            '/account' === path ? '!text-primary-6' : '!text-light-title'
          }`}
          to={PROFILE_PATH}
        >
          ข้อมูลส่วนตัว
        </Link>
        <Link
          className={`mb-4 hover:!text-primary-6 ${getColor(path, ADDRESS_PATH)}`}
          to={ADDRESS_PATH}
        >
          ที่อยู่จัดส่ง
        </Link>
        <Link
          className={`hover:!text-primary-6 ${getColor(path, PASSWORD_SETTING_PATH)}`}
          to={PASSWORD_SETTING_PATH}
        >
          ตั้งค่ารหัสผ่าน
        </Link>
      </div>
    </>
  );
};

const MemberMenu = () => {
  const { customer } = useCustomer();
  const managedDealers = getManagedDealers(customer!);

  const location = useLocation();
  const path = location.pathname;
  const isActiveMenu = [COMPANY_PATH, DEALER_PATH].some((item) =>
    path.startsWith(item)
  );

  return (
    <>
      <div className="border-y border-light-divider px-4 py-2 font-semibold">
        <div
          className={`flex items-center ${isActiveMenu ? 'text-primary-6' : 'text-light-title'}`}
        >
          <AccountMultiple />
          <div className="ml-2">สมาชิก</div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 bg-light-lighter p-4 pl-12">
        <Link
          className={`hover:!text-primary-6 ${getColor(path, COMPANY_PATH)}`}
          to={COMPANY_PATH}
        >
          สมาชิกบริษัท
        </Link>
        {managedDealers.length > 0 && (
          <Link
            className={`hover:!text-primary-6 ${getColor(path, DEALER_PATH)}`}
            to={DEALER_PATH}
          >
            สมาชิกดีลเลอร์
          </Link>
        )}
      </div>
    </>
  );
};

const OrderMenu = () => {
  const location = useLocation();
  const path = location.pathname;
  const { customer } = useCustomer();

  const isActiveMenu = [ORDERS_PATH, DEALER_ORDERS_PATH].some((item) =>
    path.startsWith(item)
  );

  return (
    <>
      <div className="border-y border-light-divider px-4 py-2 font-semibold">
        <div
          className={`flex items-center hover:!text-primary-6 ${isActiveMenu ? 'text-primary-6' : 'text-light-title'}`}
        >
          <DocumentIcon />
          <div className="ml-2">คำสั่งซื้อ</div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 bg-light-lighter p-4 pl-12">
        <Link
          className={`hover:!text-primary-6 ${getColor(path, ORDERS_PATH)}`}
          to={ORDERS_PATH}
        >
          คำสั่งซื้อของฉัน
        </Link>
        {getManagedDealers(customer!).length > 0 && (
          <Link
            className={`hover:!text-primary-6 ${getColor(path, DEALER_ORDERS_PATH)}`}
            to={DEALER_ORDERS_PATH}
          >
            คำสั่งซื้อดีลเลอร์
          </Link>
        )}
      </div>
    </>
  );
};

export default AccountLayout;
